<template>
    <v-layout column>
        <v-layout style="margin-top: 25px;">
            <countries-list title="Страна создания запроса"/>
            <periods/>
        </v-layout>

        <v-layout fill-height align-start>
            <main-count :count="ordersTotalCount.total"
                        :loading="ordersLoading"
                        label="Создано запросов"/>

            <v-layout column justify-space-between style="max-width: 350px;">
                <label class="subtitle-1 font-weight-regular"
                       style="margin-top: 40px; margin-bottom: 10px;">Активные</label>
                <v-layout>
                    <percent :count="ordersTotalCount.activeWithOffer"
                             :loading="ordersLoading"
                             label="с предложениями"/>
                    <percent :count="ordersTotalCount.activeWithoutOffer"
                             :loading="ordersLoading"
                             label="без предложений"/>
                </v-layout>
            </v-layout>

            <div class="delimeter"></div>

            <v-layout column justify-space-between style="max-width: 350px;">
                <label class="subtitle-1 font-weight-regular"
                       style="margin-top: 40px; margin-bottom: 10px;">Остановленные</label>
                <v-layout>
                    <percent :count="ordersTotalCount.inactiveWithOffer"
                             :loading="ordersLoading"
                             label="с предложениями"/>
                    <percent :count="ordersTotalCount.inactiveWithoutOffer"
                             :loading="ordersLoading"
                             label="без предложений"/>
                </v-layout>
            </v-layout>
        </v-layout>

        <template v-if="ordersTotalCount.total">
            <stacked-chart title="По области поиска"
                           :stacks="ordersStacks"
                           :data="ordersData"
                           :loading="ordersLoading"/>

            <chart-xls-label
                    :title="`Показаны самые популярные ${$route.query.idCountry ? 'области' : 'страны'}. Данные по всем странам и областям доступны в файле.`"
                    @loadXLS="loadXLS('orders')"
                    loadXLS/>
        </template>

        <v-layout fill-height align-start>
            <main-count :count="offersTotalCount.total"
                        :loading="offersLoading"
                        label="Добавлено предложений"/>
            <v-layout style="margin-top: 70px;">
                <percent :count="offersTotalCount.withResponse"
                         :loading="offersLoading"
                         label="с ответом покупателя"/>
            </v-layout>
        </v-layout>

        <template v-if="offersTotalCount.total">
            <stacked-chart title="По стране разборки"
                           :loading="offersLoading"
                           :data="offersData"
                           :stacks="offersStacks"/>

            <chart-xls-label
                    title="Показаны самые популярные страны. Данные по всем странам и областям доступны в файле."
                    @loadXLS="loadXLS('priceOffers')"
                    loadXLS/>
        </template>
    </v-layout>
</template>

<script>
    import CountriesList from "./inputs/CountriesList";
    import Periods from "./inputs/Periods";
    import Percent from "./counts/Percent";
    import MainCount from "./counts/MainCount";
    import StackedChart from "./charts/StackedChart";
    import ChartXlsLabel from "./charts/ChartXlsLabel";
    import XlsMixin from "../../mixins/XlsMixin";

    export default {
        components: {
            ChartXlsLabel,
            StackedChart,
            MainCount,
            Percent,
            Periods,
            CountriesList
        },
        mixins: [XlsMixin],
        name: "statistics-by-country",
        data: () => ({
            offersLoading: false,
            ordersLoading: false,
            ordersData: [],
            ordersTotalCount: {
                inactiveWithoutOffer: 0,
                inactiveWithOffer: 0,
                activeWithoutOffer: 0,
                activeWithOffer: 0,
                total: 0
            },
            offersTotalCount: {
                withResponse: 0,
                total: 0
            },
            offersData: [],
            ordersStacks: ['Отправлено предложений', 'Отправлено запросов'],
            offersStacks: ['С ответом покупателя', 'Отправлено предложений']
        }),
        computed: {
            timePeriods() {
                return this.$store.state.timePeriods
            }
        },
        watch: {
            '$route'() {
                this.getOrderData();
                this.getOfferData()
            }
        },
        methods: {
            getOrderData() {
                this.ordersLoading = true;
                const query = this.$route.query;

                let params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        period: query.period || this.timePeriods.default,
                        date: query.date || new Date().toISOString().substring(0, 7),
                        idCountry: query.idCountry
                    }
                };
                console.log(params.params);
                this.$http.get("/statistics/orders", params)
                    .then(response => {
                        console.log(response.data.data)
                        this.ordersTotalCount = response.data.data.totalCount
                        this.ordersData = response.data.data.items
                    })
                    .catch(error => {
                    console.log(error)
                    switch (error.response.status) {
                        case 406:
                            // this.$router.history.push({name: 'login'})
                            break;
                        case 401:
                            this.$router.history.push({name: 'login'});
                            break;
                        default:
                            console.log('error');
                            break;
                    }
                }).finally(() => {
                    setTimeout(() => {
                        this.ordersLoading = false
                    }, 500)
                })

            },
            getOfferData() {
                this.offersLoading = true;
                const query = this.$route.query;

                let params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        period: query.period || this.timePeriods.default,
                        date: query.date || new Date().toISOString().substring(0, 7),
                        idCountry: query.idCountry
                    }
                };
                console.log(params.params);
                this.$http.get("/statistics/prices", params)
                    .then(response => {
                        this.offersTotalCount = response.data.data.totalCount
                        this.offersData = response.data.data.items
                    })
                    .catch(error => {
                    console.log(error)
                    switch (error.response.status) {
                        case 406:
                            // this.$router.history.push({name: 'login'})
                            break;
                        case 401:
                            this.$router.history.push({name: 'login'});
                            break;
                        default:
                            console.log('error');
                            break;
                    }
                }).finally(() => {
                    setTimeout(() => {
                        this.offersLoading = false
                    }, 500)
                })
            },
            loadXLS(type) {
                this.downloadFile(type)
            }
        },
        beforeMount() {
            this.getOfferData();
            this.getOrderData()
        }
    }
</script>

<style scoped>
    .delimeter {
        height: 90px;
        width: 0;
        border: 1px solid #E0E0E0;
        margin: 50px 80px 0 30px;
    }

    .xls-button {
        background: #F2F2F2;
        border-radius: 8px;
        margin-left: 20px;
        width: 240px;
        height: 40px;
        color: black;
        text-align: center;
        cursor: pointer;
    }

    .xls-button * {
        cursor: pointer;
    }
</style>