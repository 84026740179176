<template>
    <v-layout column style="max-width: 400px;">
        <label class="subtitle-2 font-weight-regular">{{title}}</label>
        <v-select filled
                  class="subtitle-1 black--text"
                  placeholder="Все страны"
                  clearable
                  single-line
                  color="secondary"
                  dense
                  :items="countryItems"
                  item-value="idCountry"
                  item-text="title"
                  :loading="countriesLoading"
                  item-color="secondary"
                  v-model="idCountry"
                  @change="changeCountry"
                  hide-details
                  style="max-width: 300px; margin-top: 10px;"/>
    </v-layout>
</template>

<script>
    export default {
        name: "countries-list",
        props: {
            title: {
                type: String,
                required: true
            }
        },
        data: () => ({
            countriesLoading: false,
            idCountry: null
        }),
        computed: {
            countryItems() {
                return this.$store.state.customerCountryItems
            },
        },
        watch: {
            '$route'() {
                this.parseCountry()
            }
        },
        methods: {
            getCountries() {
                this.countriesLoading = true;
                const params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        role: 'customer'
                    }
                };
                this.$http.get("/countries", params)
                    .then(response => {
                        this.$store.dispatch('setCustomerCountryItems', response.data.data)
                    })
                    .catch(error => {
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.countriesLoading = false
                        }, 500)
                    })
            },
            parseCountry() {
                this.idCountry = this.$route.query.idCountry ? parseInt(this.$route.query.idCountry) : null
            },
            changeCountry(value) {
                const query = this.$route.query || {};
                this.$router.history.push({
                    name: this.$route.name, query: {...query, idCountry: value}
                })
            },
        },
        beforeMount() {
            if (!this.countryItems.length) this.getCountries()
        },
        created() {
            this.parseCountry()
        }
    }
</script>

<style scoped>

</style>